export const apiUrls = {
  timeZone: 'api/TimeZone',
  airportHoursSchedule: '/api/AirportHours/Schedule',
  state: 'api/State',
  city: 'api/City',
  country: 'api/Country',
  permit: 'api/Permit',
  region: 'api/Region',
  metro: 'api/Metro',
  referenceData: 'api/referenceData',
  accessLevel: 'api/AccessLevel',
  sourceType: 'api/SourceType',
  farType: 'api/FARType',
  audit: 'api/AuditHistory',
  bulletin: 'api/Bulletin',
  bulletinLevel: 'api/BulletinLevel',
  bulletinType: 'api/BulletinType',
  bulletinSource: 'api/BulletinSource',
  bulletinPriority: 'api/BulletinPriority',
};

export const vendorApiUrls = {
  vendorManagement: 'api/vendormanagement',
};

export const permitURLs = {
  permitType: 'api/PermitType',
  purposeOfFlight: 'api/PurposeOfFlight',
  flightOperationalCategory: 'api/FlightOperationalCategory',
  document: 'api/Document',
};

export const aircraftURLs = {
  ICAOTypeDesignator: 'api/ICAOTypeDesignator',
  noiseChapter: 'api/NoiseChapter',
};

export const userManagementApiUrls = {
  userRef: '/api/v3/users',
};
