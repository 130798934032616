import { IAPIFilterDictionary } from '@wings-shared/core';
import { BULLETIN_FILTERS } from './Enums';
import { baseGridFiltersDictionary } from '../../Tools';

export const gridFilters: IAPIFilterDictionary<BULLETIN_FILTERS>[] = [
  ...baseGridFiltersDictionary<BULLETIN_FILTERS>(),
  {
    columnId: 'bulletinLevel',
    apiPropertyName: 'BulletinLevel.Name',
    uiFilterType: BULLETIN_FILTERS.BULLETIN_LEVEL,
  },
  {
    columnId: 'bulletinEntity',
    apiPropertyName: 'BulletinEntity.Name',
    uiFilterType: BULLETIN_FILTERS.BULLETIN_ENTITY,
  },
  {
    columnId: 'bulletinSource',
    apiPropertyName: 'BulletinSource.Name',
    uiFilterType: BULLETIN_FILTERS.BULLETIN_SOURCE,
  },
  {
    columnId: 'bulletinPriority',
    apiPropertyName: 'BulletinPriority.Name',
    uiFilterType: BULLETIN_FILTERS.BULLETIN_PRIORITY,
  },
  {
    columnId: 'uaOffice',
    apiPropertyName: 'UAOffice.Name',
    uiFilterType: BULLETIN_FILTERS.UA_OFFICE,
  },
  {
    columnId: 'startDate',
    apiPropertyName: 'StartDate',
  },
  {
    columnId: 'endDate',
    apiPropertyName: 'EndDate',
  },
  {
    columnId: 'bulletinText',
    apiPropertyName: 'BulletinText',
  },
  {
    columnId: 'notamNumber',
    apiPropertyName: 'NotamNumber',
  },
  {
    columnId: 'internalNotes',
    apiPropertyName: 'InternalNotes',
  },
  {
    columnId: 'sourceNotes',
    apiPropertyName: 'SourceNotes',
  },
  {
    columnId: 'isUFN',
    apiPropertyName: 'IsUFN',
  },
];
