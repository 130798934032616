import { IAPIBulletin, IAPIBulletinRequest } from '../Interfaces';
import { CoreModel, EntityMapModel, IdNameCodeModel, modelProtection, SettingsTypeModel } from '@wings-shared/core';
import { UAOfficesModel } from './UAOffices.model';

@modelProtection
export class BulletinModel extends CoreModel {
  startDate: string = '';
  endDate: string = '';
  bulletinText: string = '';
  internalNotes: string = '';
  sourceNotes: string = '';
  dmNotes: string = '';
  notamNumber: string = '';
  vendorName: string = '';
  isUFN: boolean = false;
  runTripChecker: boolean = false;
  uaOffice: UAOfficesModel;
  bulletinLevel: SettingsTypeModel;
  bulletinEntity: EntityMapModel;
  bulletinSource: SettingsTypeModel;
  appliedBulletinTypes: SettingsTypeModel[] = [];
  bulletinPriority: SettingsTypeModel;

  constructor(data?: Partial<BulletinModel>) {
    super(data);
    Object.assign(this, data);
    this.uaOffice = data?.uaOffice ? new UAOfficesModel(data?.uaOffice) : null;
    this.bulletinLevel = data?.bulletinLevel ? new SettingsTypeModel(data?.bulletinLevel) : null;
    this.bulletinSource = data?.bulletinSource ? new SettingsTypeModel(data?.bulletinSource) : null;
    this.bulletinEntity = data?.bulletinEntity ? new EntityMapModel(data?.bulletinEntity) : null;
    this.appliedBulletinTypes = Array.isArray(data?.appliedBulletinTypes)
      ? data?.appliedBulletinTypes?.map(x => new SettingsTypeModel(x))
      : [];
    this.bulletinPriority = data?.bulletinPriority ? new SettingsTypeModel(data?.bulletinPriority) : null;
  }

  static deserialize(apiData: IAPIBulletin): BulletinModel {
    if (!apiData) {
      return new BulletinModel();
    }

    const data: Partial<BulletinModel> = {
      ...apiData,
      ...CoreModel.deserializeAuditFields(apiData),
      id: apiData.bulletinId || apiData.id,
      appliedBulletinTypes: apiData.appliedBulletinTypes?.map(
        x =>
          new SettingsTypeModel({
            ...x.bulletinType,
            id: x.bulletinType.bulletinTypeId,
          })
      ),
      uaOffice: new UAOfficesModel({
        ...apiData.uaOffice,
        id: apiData.uaOffice?.uaOfficeId || apiData.uaOffice?.id,
      }),
      bulletinLevel: new SettingsTypeModel({
        ...apiData.bulletinLevel,
        id: apiData.bulletinLevel?.bulletinLevelId || apiData.bulletinLevel?.id,
      }),
      bulletinSource: new SettingsTypeModel({
        ...apiData.bulletinSource,
        id: apiData.bulletinSource?.bulletinSourceId || apiData.bulletinSource?.id,
      }),
      bulletinEntity: new EntityMapModel({
        ...apiData.bulletinEntity,
        entityId: apiData.bulletinEntity?.bulletinEntityId || apiData.bulletinEntity?.id,
      }),
      bulletinPriority: new SettingsTypeModel({
        ...apiData.bulletinPriority,
        id: apiData.bulletinPriority?.bulletinPriorityId || apiData.bulletinPriority?.id,
      }),
    };
    return new BulletinModel(data);
  }

  // serialize object for create/update API
  public serialize(): IAPIBulletinRequest {
    return {
      id: this.id || 0,
      startDate: this.startDate || null,
      endDate: this.endDate || null,
      bulletinText: this.bulletinText,
      internalNotes: this.internalNotes,
      sourceNotes: this.sourceNotes,
      dmNotes: this.dmNotes,
      vendorName: this.vendorName,
      notamNumber: this.notamNumber,
      isUFN: this.isUFN || false,
      runTripChecker: this.runTripChecker || false,
      uaOfficeId: this.uaOffice?.id,
      uaOfficeName: this.uaOffice?.name,
      bulletinLevelId: this.bulletinLevel?.id,
      bulletinSourceId: this.bulletinSource?.id,
      bulletinEntityId: this.bulletinEntity?.entityId,
      bulletinEntityName: this.bulletinEntity?.name,
      bulletinEntityCode: this.bulletinEntity?.code,
      bulletinTypeIds: this.appliedBulletinTypes?.map(x => x.id),
      bulletinPriorityId: this.bulletinPriority?.id,
      ...this._serialize(),
    };
  }

  static deserializeList(apiBulletins: IAPIBulletin[]): BulletinModel[] {
    return apiBulletins ? apiBulletins.map((bulletin: IAPIBulletin) => BulletinModel.deserialize(bulletin)) : [];
  }
}
