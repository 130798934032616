import { observer } from 'mobx-react';
import React, { ReactNode } from 'react';
import { finalize } from 'rxjs/operators';

import { withStyles } from '@material-ui/core';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { Dropdown, DROPDOWN_TRIGGER } from '@uvgo-shared/dropdown';
import { ModalStore } from '@uvgo-shared/modal-keeper';
import { THEMES } from '@wings-shared/core';
import { ENVIRONMENT_VARS, EnvironmentVarsStore } from '@wings-shared/env-store';
import { ThemeStore } from '@wings-shared/layout';
import { AuthStore } from '@wings-shared/security';
import ModuleStore from '../../Stores/Module.store';
import { MultiHeader } from '../MultiHeader';
import { styles } from './Header.styles';
import UserSettings from '../UserSettings/UserSettings';
import { DropdownItem } from '@wings-shared/form-controls';

interface IProps {
  [key: string]: any;
}

@observer
class HeaderApp extends React.Component<IProps> {
  private readonly env = new EnvironmentVarsStore();

  constructor(props) {
    super(props);
  }

  private get dropdownOptions(): ReactNode {
    const isLightTheme = ThemeStore.currentTheme === THEMES.LIGHT;
    return (
      <React.Fragment>
        <DropdownItem isHeaderItem={true} onClick={() => ModalStore.open(<UserSettings />)}>
          User Settings
        </DropdownItem>
        <DropdownItem isHeaderItem={true} isRed onClick={() => this.onLogout()}>
          Logout
        </DropdownItem>
        <DropdownItem isHeaderItem={true} isSubtitle>
          Themes
        </DropdownItem>
        <DropdownItem isHeaderItem={true} onClick={() => ThemeStore.toggleTheme()}>
          {isLightTheme ? 'Dark' : 'Light'}
        </DropdownItem>
      </React.Fragment>
    );
  }

  private get userDropdown() {
    return (
      <React.Fragment>
        <Dropdown popperContent={this.dropdownOptions} trigger={DROPDOWN_TRIGGER.CLICK} autoclose={false}>
          <div style={styles.profile}>
            <span>{AuthStore.user?.name}</span>
            <ArrowDropDownOutlinedIcon style={styles.dropdown} />
          </div>
        </Dropdown>
      </React.Fragment>
    );
  }

  private onLogout(): void {
    AuthStore.logout()
      .pipe(finalize(() => ThemeStore.setDefaultTheme()))
      .subscribe();
  }

  public render() {
    return (
      <MultiHeader
        uvGoLink={this.env.getVar(ENVIRONMENT_VARS.UVGO_WEBSITE)}
        links={ModuleStore.menuList}
        rightSide={this.userDropdown}
      />
    );
  }
}

export default withStyles(styles, { name: 'wings-header' })(HeaderApp);
