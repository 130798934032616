import { ShallowWrapper, ReactWrapper } from 'enzyme';
import { GridOptions, ColumnApi } from 'ag-grid-community';
import { AgGridMasterDetails, CustomAgGridReact } from '@wings-shared/custom-ag-grid';
import { IAgGridPropsMock, IParentComponentMock } from '../Interfaces';
import { GridApiMock } from '../Mocks';
import { GRID_ACTIONS } from '@wings-shared/core';

export class AgGridTestingHelper {
  private isMount: boolean = false; // If mount true then it's a ReactWrapper
  private wrapper: ShallowWrapper | ReactWrapper;

  constructor(wrapper: ShallowWrapper | ReactWrapper, isMount = false) {
    this.isMount = isMount;
    this.wrapper = wrapper;
  }

  getGridOptions = (): GridOptions => this.getAgGridProps().gridOptions;

  getAgGridComponent = (hasMasterDetails: boolean = true) => {
    const masterDetails = hasMasterDetails ? this.wrapper.find(AgGridMasterDetails) : this.wrapper;
    const agGrid = masterDetails.find(CustomAgGridReact);
    return this.isMount ? agGrid : (agGrid as ShallowWrapper).dive();
  };

  getAgGridProps = (): IAgGridPropsMock => {
    return this.wrapper.find(CustomAgGridReact).props() as IAgGridPropsMock;
  };

  getCellEditorParams = () => this.getGridOptions()?.defaultColDef?.cellEditorParams;

  // Call on Action From Child
  onAction = (gridAction: GRID_ACTIONS, value: any) => {
    const cellEditorParams = this.getGridOptions()?.defaultColDef?.cellEditorParams;
    if (cellEditorParams?.onAction) {
      cellEditorParams?.onAction(gridAction, value);
      if (this.isMount) {
        return;
      }
      // used to refresh component forcefully
      (this.wrapper as ShallowWrapper).setProps({ abc: Math.random() });
    }
  };

  getParentComponent = (): IParentComponentMock => this.getGridOptions()?.context?.componentParent;

  // Init grid internal props
  initAgGridAPI = () => {
    const options = this.getGridOptions();
    if (options.onGridReady) {
      options.onGridReady({ api: new GridApiMock(), columnApi: new ColumnApi(), type: '' });
    }
  };
}
