export const styles = {
  profile: {
    marginLeft: '15px',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  dropdown: {
    width: '13px',
    height: '13px',
  },
};
